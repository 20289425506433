<template>
    <div class="avatar_box">
        <div class="avatar_img">
            <a-avatar shape="square" :size="44" class="avatar" :title="value">
                <span class="name">{{ avatar_name || value }}</span>
            </a-avatar>
            <span
                v-if="show_close"
                @click="close"
                class="iconfont close iconshanchu1"
            ></span>
            <span
                v-if="show_enter"
                class="iconfont close enter iconwoderili"
            ></span>
        </div>
        <div v-if="show_name" class="name_footer">{{ value }}</div>
    </div>
</template>

<script>
export default {
    props: {
        value: String,
        avatar_name: {
            type: String,
            default: ''
        },
        show_close: {
            type: Boolean,
            default: false,
        },
        show_enter: {
            type: Boolean,
            default: false,
        },
        show_name: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="less" scoped>
.avatar_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    // cursor: default;
    // user-select: none;
}
.avatar_img {
    position: relative;
    .close {
        position: absolute;
        right: -8px;
        top: -8px;
        line-height: 1;
        position: absolute;
        background: #fff;
        border-radius: 50%;
        &.enter {
            color: rgb(0, 184, 84);
        }
    }
}
.avatar {
    background: linear-gradient(180deg, #ffa12e, #f28302);
}
.name {
    font-size: 12px;
    font-weight: normal;
    display: block;

    // linear-gradient(180deg,#ffa12e,#f28302)
}
.name_footer {
    font-size: 14px;
    margin-top: 4px;
    text-align: center;
}
</style>